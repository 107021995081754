<template>
  <div>
    <!-- /api/v1/document/list文档列表 free_type(文档类型eg：vip，付费) ext(文档格式eg：ppt) 
      order(最新:id desc 推荐: desc) -->
    <div class="contain1">
      <div class="contain1_1">
        <img src="@/assets/image/icon25.png" alt="" class="leftimg" />
        <div class="nav">
          <div class="nav_item" v-for="(item, index) in breadcrumb" :key="index" @click="goPage(item, index)">
            <div>{{ item.title }}</div>
            <div v-if="breadcrumb.length - 1 != index">/</div>
          </div>
        </div>
      </div>
      <div class="contain1_2" style="margin-bottom: 20px" v-if="firstLevelMenu.length > 0">
        <div class="left">
          <img src="@/assets/image/icon26.png" alt="" />
          <div>文档分类</div>
        </div>
        <div class="nav">
          <div class="nav_item" :class="{ nav_item_active: item.id == parent_id }" v-for="(item, index) in firstLevelMenu"
            :key="index" @click="handleFirstLevelMenuClick(item)">
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="contain1_2" style="margin-bottom: 20px" v-if="secondLevelMenu.length > 1">
        <div class="left" style="margin-right: 125px;"></div>
        <div class="nav">
          <div class="nav_item" :class="{ nav_item_active: item.id == id }" v-for="(item, index) in secondLevelMenu"
            :key="index" @click="handsecondLevelMenuClick(item)">
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="contain1_2" style="margin-bottom: 20px">
        <div class="left">
          <img src="@/assets/image/icon26.png" alt="" />
          <div>文档类型</div>
        </div>
        <div class="nav">
          <div class="nav_item" :class="{ nav_item_active: item.key == currentType }" v-for="(item, index) in type"
            :key="index" @click="changeType(item)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="contain1_2">
        <div class="left">
          <img src="@/assets/image/icon27.png" alt="" />
          <div>文档格式</div>
        </div>
        <div class="nav">
          <div class="nav_item" :class="{ nav_item_active: item.key == currentFormat }" v-for="(item, index) in format"
            :key="index" @click="changeFormat(item)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 广告位 -->
    <div class="ad" @click="link_web">
      <!-- {{ $imgURL+adv.image }} -->
      <img :src="$imgURL + adv.image" alt="" />
    </div>
    <!-- 文档列表 -->
    <div class="contain2">
      <div class="screen_contain">
        <div class="screen_item" :class="{ screen_item_active: current == item.key }" v-for="(item, index) in screen"
          :key="index" @click="changeScreen(item)">
          <div>{{ item.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="contain2_line"></div>
      <div class="list_item" v-for="(item, index) in list" :key="index" @click="goDocDetail(item)">
        <div class="item_left">
          <img :src="`${$imgURL + '/view/cover/' + item.attachment.hash}`" alt="" />
        </div>
        <div class="item_right">
          <div class="item_right_title">
            <span style="margin-right: 10px;">¥{{ item.price }}</span>{{ item.title }}{{ item.ext }}
          </div>
          <div class="item_right_info">
            {{ item.description }}
          </div>
          <div class="item_right_tiem">
            <div>
              内容提供方:{{ item.username }}
              <el-divider direction="vertical"></el-divider>
              {{ item.created_at }}发布
              <el-divider direction="vertical"></el-divider>
              {{ formatBytes(item.size) }}
              <el-divider direction="vertical"></el-divider>
              {{ item.pages }}页
            </div>
            <!-- <div class="item_right_star">
              <img src="@/assets/image/icon22.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination background layout="prev, pager, next" :current-page="page" :page-size="size" :total="total"
          @current-change="getDocumentList">
        </el-pagination>
      </div>
      <div style="height: 50px"></div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { get, postJSON } from "/utils/request";
import { formatBytes } from "/utils/utils";
export default {
  data() {
    return {
      breadcrumb: [{ id: "", title: "首页" }],
      type: [
        { id: 1, name: "全部", key: "" },
        // { id: 2, name: "VIP文档", key: "vip" },
        { id: 3, name: "付费文档", key: "charge" },
        { id: 4, name: "积分文档", key: "free" }, //积分文档就是普通文档
      ], //文档类型
      format: [
        { id: 1, name: "全部", key: "" },
        { id: 2, name: "PDF", key: "pdf" },
        { id: 3, name: "DOC", key: "doc" },
        { id: 4, name: "PPT", key: "ppt" },
        { id: 5, name: "XLS", key: "xls" },
        { id: 6, name: "TXT", key: "txt" },
        { id: 7, name: "JPG", key: "jpg" },
        { id: 8, name: "PNG", key: "png" },
        { id: 9, name: "GIF", key: "gif" },
        { id: 10, name: "其他", key: "other" },
      ], //文档格式
      currentType: "",
      currentFormat: "",
      current: "recommend_at desc",
      screen: [
        { id: 1, name: "推荐文章", key: "recommend_at desc" },
        { id: 2, name: "最新上传", key: "id desc" },
      ],
      page: 1,
      size: 10,
      total: 0,
      list: [], //文档列表
      id: undefined,
      parent_id: null,
      title: '',
      adv: {},
      menuData: [], // 菜单数据数组
      firstLevelMenu: [],
      secondLevelMenu:[]
    };
  },
  created() {
    let { id, parent_id, title } = this.$route.query
    this.id = id
    this.parent_id = parent_id
    this.title = title
    this.getCategoryList();
    this.getDocumentList(this.page);
  },
  watch: {
    $route(to) {
      let id = to.query.id;
      let parent_id = to.query.parent_id;
      let title = to.query.title;
      this.breadcrumb = [{ id: "", title: "首页" }, { id: "全部分类", title: "全部分类" }];
      this.title = title
      // this.currentFormat = to.query.currentFormat
      if (id && parent_id) {
        this.id = id;
        this.parent_id = parent_id;
      } else {
        this.id = id;
      }
      this.getCategoryList();
      this.getDocumentList(this.page);
    }
  },
  methods: {
    formatBytes,
    goPage(item, index) {
      if (item.title == "首页") {
        this.$router.replace("/");
        return;
      }
      if (item.title == "全部分类") {
        get("/category/list", {}).then((res) => {
          this.$router.push({ name: "documentClassify", query: { id: res.category[0].id, title: '全部分类' } });
        })

        // this.$router.replace("/");
        return;
      }
      if (index == 2) {
        this.breadcrumb = [{ id: "", title: "首页" }, { id: "全部分类", title: "全部分类" }];
        this.page = 1;
        this.total = 0;
        this.list = [];
        this.id = item.id;
        this.getCategoryList();
        this.getDocumentList(this.page);
      }
    },
    handleFirstLevelMenuClick(v) {
      if(v.id == 0) {
        this.secondLevelMenu = []
        this.parent_id = v.id
        this.id = v.id
        this.page = 1;
        this.total = 0;
        this.list = [];
        this.getDocumentList();
      } else {
        this.parent_id = v.id
        this.updateSecondLevelMenu(v.id)
      }
    },
    // 更新二级菜单
    updateSecondLevelMenu(v) {
      this.parent_id = v
      this.secondLevelMenu = this.menuData.filter(item => item.parent_id === Number(v));
      this.secondLevelMenu.unshift({ title: '全部', id: v })
      this.id = this.secondLevelMenu[0].id
      this.page = 1;
      this.total = 0;
      this.list = [];
      this.getDocumentList();
    },
    // 点击二级菜单
    handsecondLevelMenuClick(v) {
      this.id = v.id
      this.page = 1;
      this.total = 0;
      this.list = [];
      this.getDocumentList();
    },
    changeChildren_arr(item) {
      if (!item.parent_id) {
        window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}` }).href, '_blank');
        // this.$router.replace({ name: "documentClassify", query: { id: item.id } });
        this.$router.replace({ name: "documentClassify", query: { id: item.id } });
        return
      }
      this.id = item.id;
      this.page = 1;
      this.total = 0;
      this.list = [];
      // window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}` }).href, '_blank');
      this.getDocumentList();
    },
    changeType(item) {
      this.currentType = item.key;
      this.page = 1;
      this.total = 0;
      this.list = [];
      this.getDocumentList();
    },
    changeFormat(item) {
      this.currentFormat = item.key;
      this.page = 1;
      this.total = 0;
      this.list = [];
      // window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}&currentFormat=${item.key}` }).href, '_blank');
      this.getDocumentList();
    },
    changeScreen(item) {
      this.current = item.key;
      this.page = 1;
      this.total = 0;
      this.list = [];
      this.getDocumentList();
    },
    getCategoryList() {
      // 获取的广告位
      postJSON("/adv", { adv_id: 2 }).then((res) => {
        this.adv = res.data ?? {};
      });
      get("/category/list", {}).then((res) => {
        // 首先过滤出一级菜单和二级菜单
        this.menuData = res.category
        this.firstLevelMenu = this.menuData.filter(item => !item.parent_id)
        this.firstLevelMenu.unshift({ title:'全部', id: 0 })
        // 先确认一级菜单
        let vid = this.$route.query.id
        if(this.firstLevelMenu.some(item => item.id == vid)) {
          this.updateSecondLevelMenu(vid)
        } else {
          let obj = this.menuData.find(item => item.id === Number(vid))
          this.secondLevelMenu = this.menuData.filter(item => item.parent_id === obj.parent_id);
          this.secondLevelMenu.unshift({ title: '全部', id: obj.parent_id })
          this.parent_id = obj.parent_id
          this.id = obj.id
          this.page = 1;
          this.total = 0;
          this.list = [];
          this.getDocumentList();
        }
        if (this.title == '全部分类') {
          this.breadcrumb = [{ id: "", title: "首页" }, { id: "全部分类", title: "全部分类" }];
          return
        }
        // let once_category = [];
        // let two_category = [];
        // res.category.map((item) => {
        //   if (!item.parent_id) {
        //     let categories_obj = {
        //       ...item,
        //       children: [],
        //     };
        //     once_category.push(categories_obj);
        //   } else {
        //     two_category.push(item);
        //   }
        // });
        // once_category.map((item) => {
        //   two_category.map((item2) => {
        //     if (item.id == item2.parent_id) {
        //       item.children.push(item2);
        //     }
        //   });
        // });
        // if (this.id && this.parent_id) {
        //   once_category.map((item) => {
        //     if (item.id == this.parent_id) {
        //       this.breadcrumb.push(item);
        //       item.children.map((item2) => {
        //         if (item2.id == this.id) {
        //           this.breadcrumb.push(item2);
        //         }
        //       });
        //     }
        //   });
        //   console.log("this.breadcrumb", this.breadcrumb);
        // } else {
        //   once_category.map((item) => {
        //     if (item.id == this.id) {
        //       console.log('分类----item', item);
        //       this.children_arr = item.children
        //       this.breadcrumb.push(item);
        //     }
        //   });
        // }
      });
    },
    getDocumentList(val) {
      this.page = val;
      get("/document/list", {
        order: this.current, //推荐 最新排序
        status: 2,
        page: this.page || 1,
        size: 10,
        category_id: this.id, //分类id
        ext: this.currentFormat, //文件类型
        fee_type: this.currentType, //费用
      }).then((res) => {
        if (!res.document) {
          this.list = [];
          return;
        }
        this.total = res.total || 0;
        let arr = [];
        res.document.map((item) => {
          if (item.created_at) {
            let date = new Date(item.created_at);
            date.setHours(date.getHours());
            item.created_at = date.toLocaleString();
            console.log(date.toLocaleString());
          }
          arr.push(item);
        });
        this.list = res.document;
      });
    },
    goDocDetail(item) {
      // https://moredoc.mnt.ltd/api/v1/document?id=0&uuid=a2de7514ab3dde6f&with_author=true
      window.open(this.$router.resolve({ path: `/docDetail?id=${item.id}` }).href, '_blank');

      // this.$router.push({
      //   name: "docDetail",
      //   query: { id: item.id, uuid: item.uuid },
      // });
    },
    link_web() {
      window.location.href = this.adv.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.contain1 {
  width: 1400px;
  min-height: 148px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;

  .contain1_1 {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .leftimg {
      width: 28px;
      height: 28px;
    }

    .nav {
      display: flex;
      align-items: center;

      .nav_item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
        cursor: pointer;

        div:nth-child(1) {
          margin: 0 6px;
        }
      }
    }
  }

  .contain1_2 {
    display: flex;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #999999;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      & div {
        width: 100px;
      }
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .nav_item {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        margin-right: 30px;
        cursor: pointer;
      }

      .nav_item_active {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #23dc5a;
        margin-right: 30px;
        cursor: pointer;
      }
    }
  }
}

.ad {
  width: 1400px;
  height: 88px;
  margin: 20px auto;
  border-radius: 6px;
  cursor: pointer;

  img {
    width: 1400px;
    height: 88px;
    border-radius: 6px;
  }
}

.contain2 {
  width: 1400px;
  // height: 2357px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .screen_contain {
    display: flex;
    align-items: center;

    .screen_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;

      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
      }

      .line {
        width: 72px;
        height: 3px;
        background: transparent;
      }
    }

    .screen_item_active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;

      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        margin-bottom: 13px;
      }

      .line {
        width: 72px;
        height: 3px;
        background: #23dc5a;
      }
    }
  }

  .contain2_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }

  .list_item {
    display: flex;
    margin-bottom: 24px;
    // justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #f6f7f8;
    padding-bottom: 24px;

    .item_left {
      img {
        // height: 175px;

        width: 114px;
        height: 160px;
        border: 1px solid #c6c6c6;
        border-radius: 6px;
        margin-right: 20px;
      }
    }

    .item_right {
      width: 1226px;
      // min-height: 200px;

      .item_right_title {
        width: 926px;
        height: 25px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333333;
        letter-spacing: 0.9px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // margin-top: 26px;
      }

      .item_right_info {
        max-height: 44px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        letter-spacing: 0.63px;
        margin: 20px 0 31px;
        width: 1226px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .item_right_tiem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.63px;
        }

        .item_right_star {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .item_right_line {
        width: 926px;
        height: 1px;
        background: #f4f4f4;
        margin: 45px 0 15px 0;
      }
    }
  }

  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;

    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }

    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;

      .el-icon-arrow-left {
        font-size: 24px;
      }
    }

    ::v-deep .btn-next {
      width: 46px;
      height: 46px;

      .el-icon-arrow-right {
        font-size: 24px;
      }
    }

    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>